<template>
	<div class="">
		<div class="row">
			<div class="col-12 col-xl-6">
				<div class="card">
					<div class="card-header justify-content-between">
						<h4 class="card-header-title">Admin User Information</h4>
						<div class="dropdown">
							<button
								class="btn btn-danger dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-expanded="false"
								>
								Actions
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a
									v-if="userHasPermission('staff003')"
									class="dropdown-item"
									href="#"
									@click.prevent="showChangePassword = true"
									>Change Password</a
									>
								<a
								v-if="getAdminType() !== 'user'"
									class="dropdown-item text-danger"
									href="#"
									@click.prevent="suspendOrUnsuspendUser"
									>{{ isUserActive ? 'Suspend' : 'Unsuspend' }} Admin</a
									>
							</div>
						</div>
					</div>
					<div class="card-body p-2">
						<div class="list-group list-group-flush my-4">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<span class="text-muted text-uppercase">Name</span>
									</div>
									<div class="col-auto">
										<span class="">{{ `${user.fname} ${user.lname}` }}</span>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<span class="text-muted text-uppercase">Phone Number</span>
									</div>
									<div class="col-auto">
										<span class="">{{ user.phone }}</span>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<span class="text-muted text-uppercase">Email</span>
									</div>
									<div class="col-auto">
										<span class="">{{ user.email }}</span>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<span class="text-muted text-uppercase">A/B Testing role</span>
									</div>
									<div class="col-auto">
										<span class="">{{ user.ab_testing_role ? user.ab_testing_role : '-' }}</span>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<span class="text-muted text-uppercase">Status</span>
									</div>
									<div class="col-auto">
										<span
											:class="isUserActive ? 'text-success' : 'text-danger'"
											>{{ isUserActive ? 'Active' : 'Inactive' }}</span
											>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-6">
				<div class="card">
					<div class="card-header">
						<div class="row align-items-center">
							<div class="col">
								<h4 class="card-header-title">Update Admin User</h4>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div
							class="alert alert-danger alert-dismissible fade show"
							role="alert"
							v-if="errorMessage"
							>
							{{ errorMessage }}
						</div>
						<form @submit.prevent="updateUser">
							<div class="row mt-4">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> First name </label>
										<input
											v-model.trim="updateUserModel.fname"
											:disabled="getAdminType() === 'user'"
											required
											type="text"
											class="form-control"
											/>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> Last name </label>
										<input
											v-model.trim="updateUserModel.lname"
											required
											:disabled="getAdminType() === 'user'"
											type="text"
											class="form-control"
											/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="mb-1"> Email address </label>
										<input
											v-model.trim="updateUserModel.email"
											required
											:disabled="getAdminType() === 'user'"
											type="email"
											class="form-control"
											/>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label class="form-label"> Phone </label>
										<input
											v-model.trim="updateUserModel.phone"
											required
											:disabled="getAdminType() === 'user'"
											type="text"
											class="form-control mb-3"
											placeholder="(___)___-____"
											inputmode="text"
											/>
									</div>
								</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Staff Role </label>
											<select
												class="form-select form-control mb-3"
												:disabled="getAdminType() === 'user'"
												v-model.trim="updateUserModel.role"
												required
												>
												<option value="super_admin" 	v-if="getAdminType() === 'super_admin'">Super Admin</option>
												<option value="admin">Admin</option>
												<option value="user">User</option>
											</select>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> A/B Testing role </label>
											<select
												class="form-select form-control mb-3"
												:disabled="getAdminType() === 'user'"
												v-model="updateUserModel.ab_testing_role"
												required
												>
												<option value="NON" 	v-if="getAdminType() === 'super_admin'">Non</option>
												<option value="A" 	v-if="getAdminType() === 'super_admin'">A</option>
												<option value="B" 	v-if="getAdminType() === 'super_admin'">B</option>
											</select>
										</div>
									</div>
							</div>
							<button
								v-if="userHasPermission('staff008') && getAdminType() !== 'user'"
								type="submit"
								class="btn btn-primary mt-4"
								:disabled="processing"
								>
								{{ processing ? 'Processing...' : 'Update User' }}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<change-user-password-modal
			:show="showChangePassword"
			:user="user"
			entity-type="staff"
			@close="showChangePassword = false"
			></change-user-password-modal>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
import { getAdminType } from '@/composables/core/permissions/admin'
</script>

<script>
import Swal from 'sweetalert2'
import ChangeUserPasswordModal from '@/components/modals/ChangeUserPasswordModal.vue'



export default {
  props: {
    user: Object,
    entityType: String
  },
  components: {
    ChangeUserPasswordModal
  },
  data () {
    return {
      updateUserModel: null,
      processing: false,
      errorMessage: '',
      showChangePassword: false,
    }
  },
  watch: {
    user: {
      immediate: true,
      handler: function (value) {
		  if (value) {
          this.updateUserModel = { ...value }
        }
      }
    }
  },
  computed: {
    isUserActive () {
      if (!this.user) {
        return false
      }

      return !!parseInt(this.user.active)
    }
  },
  methods: {
    async suspendOrUnsuspendUser () {
      const isSuspendAction = this.isUserActive

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isUserActive ? 'suspend' : 'unsuspend'
        } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/${this.entityType}/${this.user.id}`, {
              active: this.isUserActive ? 0 : 1
            })
            .then((res) => {
              this.$emit('profile-updated', res.data)
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              icon: 'success',
              title: 'Success',
              text: `User has been ${
              isSuspendAction ? 'suspended' : 'unsuspended'
            } successfully`,
              showCloseButton: true
            })
          }
        })
    },
    updateUser () {
      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/${this.entityType}/${this.user.id}`, {
          fname: this.updateUserModel.fname,
          lname: this.updateUserModel.lname,
          email: this.updateUserModel.email,
		  phone: this.updateUserModel.phone,
		  role: this.updateUserModel.role,
		  ab_testing_role: this.updateUserModel.ab_testing_role 
        })
        .then(() => {
			//   this.user = { ...this.updateUserModel }
		this.$emit('profile-updated', this.updateUserModel)

          this.$swal({
            icon: 'success',
            title: 'User Updated',
            text: 'User information has been updated successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>

<style scoped>
</style>
