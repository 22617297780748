<template>
  <div class="">
    <div v-if="loading">
      <div class="center text-center p-4">
        <span class="fe fe-spinner">Loading..</span>
      </div>
    </div>
    <div v-else>
      <div class="main-content">
        <admin-profile :user="staff" entity-type="staff" @profile-updated="(value) => (staff = { ...value })"></admin-profile>
      </div>
    </div>
  </div>
</template>


<script setup>
import AdminProfile from '@/components/modules/admins/AdminProfile.vue'
import { ref } from 'vue'
import { currentUser } from '@/composables/core/permissions'
import { getAdminType } from '@/composables/core/permissions/admin'
import { axiosInstance } from '@/plugins/axios'

const props = defineProps({
  staffId: {
    required: false,
    type: [Number, String]
  }
})

const loading = ref(false)
const errorLoading = ref(false)
const processing = ref(false)
const showChangePassword = ref(false)
const staff = ref()
const updateStaffModel = ref()
const errorMessage = ref('')
const menuTabs = [
  {
    routeName: 'StaffInformation',
    label: 'Staff information'
  },
  {
    routeName: 'Permissions',
    label: 'Permissions'
  }
]

const fetchStaffs = () =>
{
  const currentUserId = props.staffId || currentUser.value.id
  return axiosInstance.get(`/v1/staff?id=${currentUserId}`).then((res) =>
  {
    staff.value = res.data.data[0]
    updateStaffModel.value = { ...staff.value }
  })
}
const init = () =>
{
  loading.value = true
  errorLoading.value = false

  fetchStaffs()
    .then(() => { })
    .catch(() => (errorLoading.value = true))
    .finally(() => (loading.value = false))
}

init()

</script>

